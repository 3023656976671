
<template>
    <a-form @submit.prevent="handleSubmit">
        <a-form-item label="数据订阅名称">
            <a-input v-model="form.topic" placeholder="请输入Topic名称"></a-input>
        </a-form-item>

        <DeviceSelector @device-selected="handleDeviceSelected" />

        <p>devType: {{ form.devType }}</p>
        <p>subType: {{ form.subType }}</p>
        <p>sn: {{ form.sn }}</p>
        <p>sn: {{ form.deviceId }}</p>
        <!--淮安业务平台不能直接拿到设备有哪些属性，所以只能手动输入，这么说来告警应该放到物联网平台里-->
        <a-form-item label="属性">
            <a-input v-model="form.property" placeholder="请输入property"></a-input>
        </a-form-item>
        <a-form-item label="比较条件">
            <a-input v-model="form.condition" placeholder="请输入condition,如>10"></a-input>
        </a-form-item>

        <a-form-item label="监测周期" :validate-status="formError ? 'error' : ''" :help="formError">
            <a-input-group compact>
                <a-input-number v-model="form.selectPeriodNumber" style="width: 70%;" :min="1" />
                <a-select v-model="form.selectPeriodUnit" style="width: 30%;">
                    <a-select-option value="h">时</a-select-option>
                    <a-select-option value="m">分</a-select-option>
                    <a-select-option value="s">秒</a-select-option>
                </a-select>
            </a-input-group>
        </a-form-item>

        <a-form-item label="告警提示文本">
            <a-textarea v-model="form.remark" placeholder="请输入备注"></a-textarea>
        </a-form-item>

        <a-form-item>
            <a-button type="primary" html-type="submit">提交</a-button>
        </a-form-item>
    </a-form>
</template>

<script>
//import { addTopic } from "@/api/alert-manager/index.js"
import DeviceSelector from "./device-selector.vue"
// 为单个设备创建规则的页面
export default {
    name: 'AlarmRuleConfig',
    components:
    {
        DeviceSelector
    },
    data() {
        return {
            form: {
                topic: '',
                devType: '',
                subType: '',
                sn: '',
                property: '',
                condition: '',
                deviceId: '',
                remark: '',
                selectPeriodNumber: 10,
                selectPeriodUnit: 's', // 默认单位为秒

            },
            formError: '',
        };
    },
    methods: {
        handleSubmit() {
            // 处理表单提交的逻辑
            if (this.validateForm()) {
                console.log('提交的数据:', this.form);

                /*
                const submitData = {
                    ...this.form,
                    selectPeriod: selectPeriod,
                    selectPeriodNumber: undefined, // 删除不需要的属性
                    selectPeriodUnit: undefined,   // 删除不需要的属性
                    status: "subscribed",
                    type: "subQuery"
                };
                */
                const submitData = this.transformContent(this.form)
                // 转换为JSON字符串
                const jsonData = JSON.stringify(submitData);



                // 打印JSON字符串

                console.log('准备发送的JSON报文:', jsonData);



                //addTopic(submitData)
                // this.$emit('closeModal')

            }
        },
        transformContent() {
            // 将表单中的内容拼装成待发送的报文
            const selectPeriod = `${this.form.selectPeriodNumber}${this.form.selectPeriodUnit}`;

            const submitData = {
                "devType": this.form.devType,
                "deviceId": this.form.deviceId,
                "envDepend": true,
                "props": [
                    ["sn", `='${this.form.sn}'`], [`${this.form.property}`, `${this.form.condition}`]
                ],
                "remark": this.form.remark,
                "selectPeriod": selectPeriod,
                "status": 'subscribed',
                "subType": this.form.subType,
                "topic": this.form.topic,
                "type": 'subQuery'
            }
            return submitData
        },
        validateForm() {
            this.formError = '';
            const regex = /^[a-zA-Z0-9_]+$/;
            if (!regex.test(this.form.topic) || !regex.test(this.form.devType) || !regex.test(this.form.property)) {
                alert('topic, devType, 和 property 只能包含英文字母、数字和下划线');
                return false;
            }
            if (!this.form.selectPeriodNumber || !this.form.selectPeriodUnit) {
                this.formError = '请选择有效的selectPeriod';
                return false;
            }
            return true;
        },
        handleDeviceSelected(selectElement) {
            console.log(selectElement)
            this.form.devType = selectElement.devType
            this.form.subType = selectElement.subType
            this.form.sn = selectElement.sn
            this.form.deviceId = selectElement.deviceId
        }
    }
};
</script>

<style scoped>
/* 在这里添加您的样式 */
</style>