<template>
    <Card title="告警规则查看">

        <div class="container">

            <div class="search-bar">
                <a-select v-model="selectQueryType" style="width: 200px">
                    <a-select-option v-for="obj in queryTypes" :key="obj.value" :value="obj.value">
                        {{ obj.label }}
                    </a-select-option>
                </a-select>
                <a-input placeholder="请输入查询条件" v-model="queryContent" />
                <a-button type="default" @click="handleSearchButtonPressed">查询</a-button>
            </div>

            <div class="create-button">
                <a-button type="primary" @click="showModal = true">对设备类新建规则</a-button>
                <a-button type="primary" @click="showAddRuleForSingleDeviceModal = true">对单个设备新建规则</a-button>
            </div>
        </div>



        <a-modal v-model="showModal" title="新建告警规则" :footer="null">
            <AlertRuleConfig @closeModal="showModal = false" />
        </a-modal>
        <a-modal v-model="showAddRuleForSingleDeviceModal" title="新建单个告警规则" :footer="null">
            <AlertRuleForSingleDevice @closeModal="showAddRuleForSingleDeviceModall = false" />
        </a-modal>
        <div>
            <AlertRuleList :queryType="selectQueryType" :queryContent="queryContent" ref="AlertRuleList" />
        </div>

    </Card>
</template>

<script>
import AlertRuleConfig from './alert-rule-config.vue';
import AlertRuleList from './alert-rule-list.vue';
import AlertRuleForSingleDevice from './add-rule-for-single-device.vue'

const queryTypes = [
    {
        "value": "device_type",
        "label": "设备类型"
    },
    {
        "value": "id",
        "label": "规则id"
    },
    {
        "value": "topic",
        "label": "规则名称"
    },
    {
        "value": "sql_query",
        "label": "sql语句"
    }
]
export default {
    components: {
        AlertRuleConfig,
        AlertRuleList,
        AlertRuleForSingleDevice
    },
    data() {
        return {
            showModal: false,
            showAddRuleForSingleDeviceModal: false,
            queryTypes,
            selectQueryType: 'device_type',
            queryContent: ''
        };
    },
    methods: {
        handleSearchButtonPressed() {
            this.$refs.AlertRuleList.plusOne()
            this.$refs.AlertRuleList.fetchData()
        },
    }
}
</script>

<style scoped>
.container {
    margin-bottom: 20px;
    /* 距离下方内容的距离 */
    display: flex;
    justify-content: space-between;
    /* 两边元素分开 */
    align-items: center;
    /* 垂直居中 */
    color: white;
}

.search-bar {
    display: flex;
    gap: 10px;
    /* 输入框和按钮之间的间隔 */
}

.create-button {
    display: flex;
    gap: 10px;
}
</style>