import request from '../request'

export function fetchList(params) {
    return request({
        url: '/model-analysis/pipe/device/list',
        params
    })
}

export function add(data) {
    return request({
        url: '/model-analysis/pipe/device/add',
        method: 'post',
        data
    })
}

export function edit(data) {
    return request({
        url: '/model-analysis/pipe/device/update',
        method: 'post',
        data
    })
}

export function fetchDetail(data) {
    return request({
        url: `/model-analysis/pipe/device/query/${data.id}`,
        method: "get" // 2023.4.17 陈政把查询改成了Get
    })
}

export function del(data) {
    return request({
        url: '/model-analysis/pipe/device/delete',
        method: 'post',
        data
    })
}