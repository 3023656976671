<template>
    <div>
        <p>我是设备选择器</p>
        <p>设备数：{{ listCount }}</p>
        <p>{{ selectedDevice }}</p>

        <a-select v-model="selectedSubType" @change="handleSubTypeChange" style="width: 200px;">
            <a-select-option value="">请选择SubType</a-select-option>
            <a-select-option v-for="subType in subTypes" :value="subType" :key="subType">{{ subType }}</a-select-option>
        </a-select>

        <a-select v-if="forSingle" v-model="selectedDevice" @change="handleDeviceChange" style="width: 200px;">
            <a-select-option value="">请选择设备</a-select-option>
            <a-select-option v-for="device in filteredDevices" :value="device.id" :key="device.id">{{ device.name
            }}</a-select-option>
        </a-select>

    </div>
</template>
<script>
/** 增加单个设备的规则时，需要在給后端的参数中，增加devType和设备的SN
 *  直接让用户去找SN，和DevType，并不是很合适
 *  所以在这里设计一个选择器子组件，向上级输出查询结果
 *  查询接口沿用管网管理-设备管理中的接口
 */

import { fetchList } from "@/api/assets/equip.js";

export default {
    props: {
        forSingle: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            deviceList: [],
            loading: true,

            selectedSubType: '', // 选中的 SubType
            selectedDevice: '' // 选中的设备
        }
    },
    mounted() {
        this.getDeviceList()
    },
    methods: {
        async getDeviceList() {
            fetchList({
                pageNum: 1,
                pageSize: 10000
            })
                .then((res) => {
                    this.loading = false;
                    if (Array.isArray(res.list)) {
                        this.deviceList = res.list;
                    }
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        handleSubTypeChange() {
            this.selectedDevice = '';

            if (this.filteredDevices.length > 0) {
                const selectedDeviceObj = this.filteredDevices[0]
                if (this.forSingle === false) {
                    this.$emit('device-type-selected', { devType: selectedDeviceObj.type, subType: selectedDeviceObj.subType });
                }
            }
        },

        // 当设备选项发生变化时触发事件
        handleDeviceChange() {
            const selectedDeviceObj = this.deviceList.find(device => device.id === this.selectedDevice);
            if (selectedDeviceObj) {
                // 在这里把sn、devtype、向上报送
                this.$emit('device-selected', { sn: selectedDeviceObj.sn, devType: selectedDeviceObj.type, subType: selectedDeviceObj.subType, deviceId: selectedDeviceObj.id });
            }
        }
    },
    computed: {
        listCount() {
            return this.deviceList.length
        },

        subTypes() {
            const subTypesSet = new Set();
            this.deviceList.forEach(device => {
                subTypesSet.add(device.subType);
            });
            return Array.from(subTypesSet);
        },
        // 根据选中的 SubType 过滤设备列表
        filteredDevices() {
            if (!this.selectedSubType) return [];
            return this.deviceList.filter(device => device.subType === this.selectedSubType);
        },
        deviceOptions() {

            if (this.deviceList.length > 0) {
                return this.deviceList.map(device => {
                    return {
                        value: device.id, // 设备的 id 作为选项的值
                        label: device.name, // 设备的 name 作为选项的标签
                        devType: device.type, // 设备的 type 作为选项的 devType
                        sn: device.sn, // 设备的 sn 作为选项的 sn
                        subType: device.subtype // 设备的 subtype 作为选项的 subType
                    };
                });
            }
            return []
        }
    }
}
</script>
<style></style>