import request from '../request'
/** 
 * @张弘
 * @20240201
 * 
 * 这个接口是名字叫做数据订阅管理接口
 * 是用来建立对TDengine的数据订阅查询的。
 * 是陈政的业务后台里创建告警规则的一组接口。
 * 
 * 在这里能够通过接口新建一条规则
 * 查询所有规则
*/


export function addTopic(data = {}) {
    /** */
    ///const example_data = { "devType": "liquidLevel", "deviceId": "1168547271417073664", "envDepend": true, "props": [[["sn", "='nwxvz1'"], ["distance", ">1.3"]]], "remark": "液位当前值{0}m,已超过1.3m的警戒阈值", "selectPeriod": "15m", "status": "subscribe", "topic": "液位当前值{0}m,已超过1.3m的警戒阈值", "type": "subQuery" }
    return request({
        url: '/model-analysis/topic/add',
        method: 'post',
        data: data
    })
}

export function fetchTopicList(params = {}) {
    /**
     * 获取告警规则配置
     * 
     */
    // 参数校验
    if (!_fetchTopicList_validateParams(params)) {
        throw new Error('参数验证失败');
    }

    return request({
        url: '/model-analysis/topic/list',
        method: 'get',
        params: params
    });
}

// 参数校验函数
function _fetchTopicList_validateParams(params) {
    // 检查pageNum和pageSize是否是有效的数字
    if (params.pageNum !== undefined && (typeof params.pageNum !== 'number' || params.pageNum < 1)) {
        console.error('无效的pageNum：必须是大于0的数字');
        return false;
    }
    if (params.pageSize !== undefined && (typeof params.pageSize !== 'number' || params.pageSize < 1)) {
        console.error('无效的pageSize：必须是大于0的数字');
        return false;
    }

    // 这里可以添加更多的参数校验逻辑，比如检查字符串参数是否为空，检查状态参数是否在预期的范围内等

    // 如果所有检查都通过，则返回true
    return true;
}

export function updateRuleStatus(data) {
    // 

    /**
     * {
         "id": "string",
         "remark": "string",
         "status": "string"
        }
     */
    return request({
        url: '/model-analysis/topic/update',
        method: 'post',
        data: data
    });
}