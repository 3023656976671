<template>
    <div>

        <!--<div>{{ queryType }} : {{ queryContent }} - {{ pressButtonTime }}</div>-->
        <a-table :columns="columns" :dataSource="topicList" rowKey="id" class="mytable">
            <div slot="expandedRowRender" slot-scope="record" class="expand-table-item">

                <a-descriptions>
                    <a-descriptions-item label="报警内容">
                        {{ record.remark }}
                    </a-descriptions-item>
                    <a-descriptions-item label="设备类型">
                        {{ record.devType }}
                    </a-descriptions-item>
                    <a-descriptions-item label="子类型">
                        {{ record.subType }}
                    </a-descriptions-item>
                    <a-descriptions-item label="属性">
                        {{ record.propList }}
                    </a-descriptions-item>
                </a-descriptions>
            </div>
            <template slot="action" slot-scope="record">
                <!--
                <a v-if="record.status !== 'subscribed'" @click="record">启用</a>
                <a v-else>停用</a>
                -->
                <a-popconfirm v-if="record.status !== 'subscribed'" title="确定要启用吗？"
                    @confirm="confirmUpdateRuleStatusAction(record, 'subscribed')">
                    <a href="#">启用</a>
                </a-popconfirm>
                <a-popconfirm v-else title="确定要停用吗？" @confirm="confirmUpdateRuleStatusAction(record, 'unsubscribed')">
                    <a href="#">停用</a>
                </a-popconfirm>
            </template>

        </a-table>
        <a-pagination :total="totalSize" :pageSize="pageSize" :current="pageNum" @change="handlePageChange" />
    </div>
</template>

<script>
import { fetchTopicList, updateRuleStatus } from "@/api/alert-manager/index.js"
import { message } from 'ant-design-vue'
export default {
    name: 'TopicList',
    props: {
        // 接收父组件传递过来的查询参数
        queryType: String,
        queryContent: String
    },
    data() {
        return {
            alertVisible: false, // 状态改变请求发送通知Alert组件的显示
            // 表格列的定义
            columns: [
                //{ title: 'ID', dataIndex: 'id' },
                { title: '订阅名称', dataIndex: 'topic' },
                { title: '设备类型', dataIndex: 'devType' },
                { title: '子设备类型', dataIndex: 'subType' },
                { title: '规则类型', dataIndex: 'type' },
                //{ title: '属性', dataIndex: 'property' },
                //{ title: '触发条件', dataIndex: 'condition' },
                { title: '订阅状态', dataIndex: 'status' },
                { title: '创建时间', dataIndex: 'createAt' },
                { title: '检测周期', dataIndex: 'selectPeriod' },
                {
                    title: '操作',
                    key: 'action',
                    scopedSlots: { customRender: 'action' }
                }
                // { title: '评论', dataIndex: 'remark' },
                // { title: '字典', dataIndex: 'subQuery' },
            ],
            topicList: [],  // 存储API返回的列表数据
            totalSize: 0,   // 总数据条数
            pageNum: 1,     // 当前页码
            pageSize: 10,    // 每页显示条数
            pressButtonTime: 0
        };
    },
    methods: {
        // 分页改变时的处理函数
        handlePageChange(page) {
            this.pageNum = page;
            this.fetchData();
        },
        // 获取数据的方法
        async fetchData() {
            try {
                let queryParams = {
                    pageSize: this.pageSize,
                    pageNum: this.pageNum
                }
                if (this.queryType === 'topic') {
                    queryParams.topic = this.queryContent
                }
                // 调用fetchTopicList API，并传入当前的分页参数
                const data = await fetchTopicList(queryParams);
                this.topicList = data.list || [];
                this.totalSize = data.totalSize || 0;
                this.pageNum = data.pageNum || 1;
                this.pageSize = data.pageSize || 10;


            } catch (error) {
                // 处理请求错误
                console.error('请求数据时发生错误:', error);
            }
        },
        plusOne(
        ) {
            this.pressButtonTime += 1
        },
        async confirmUpdateRuleStatusAction(record, status) {
            // 定义并调用updateRuleStatusWrapper
            const updateRuleStatusWrapper = (data) => {
                return new Promise((resolve, reject) => {
                    try {
                        const result = updateRuleStatus(data);
                        resolve(result);
                    } catch (error) {
                        reject(error);
                    }
                });
            };


            console.log(record, status)
            if (status !== 'subscribed' && status !== 'unsubscribed') {
                console.log("exit")
                return // 无效的请求
            }


            const request_body = {
                id: record.id,
                remark: record.remark,
                status: status
            }
            updateRuleStatusWrapper(request_body).then(
                () => {
                    message.success("操作成功")
                }
            )

            message
        }

    },

    mounted() {
        this.fetchData();

    }
};
</script>

<style>
/** 不加scoped才能生效到ant组件里 */
/* 可以在这里添加一些样式 */

.ant-table-tbody:hover {
    background: none;
}

tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
    background: none;
    ;
}
</style>